@layer tailwind-base, primereact, tailwind-utilities;

/* IMPORTANT: In "styled" mode you must add the PrimeReact Theme here. Do NOT include in "unstyled" mode */
@import 'primereact/resources/themes/lara-light-blue/theme.css' layer(primereact);

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind base;
  @tailwind components;
  @tailwind utilities;
}
body {
  margin: 0;
  font-family: 'Kosugi',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #5e5e5e;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
p {
  margin: 0;
}

a{
  text-decoration: unset;
  cursor: pointer;
}
a:hover{
  text-decoration: unset;
  color: #e11c48 !important;
  font-weight: 800;
}
.menuRight {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: fit-content !important;
}
.loginBt {
  background: #e24189;
  border-radius: 0 0 20px 20px;
  width: auto;
  display: flex;
  flex-direction: row;
  border: 2px dotted #ffffff;
  border-top: unset;
  font-size: 0.8rem;
  padding: 0px 10px 5px 10px;
  height: 37px;
  margin: 0 5px;
}
.loginBt:hover {
  background: #ee5c5c !important;
  color:#ffffff;
  border: 2px dotted #ffffff !important;
  border-top: unset !important;
}
.preregiBt {
  background: #41e259;
    border-radius: 0 0 20px 20px;
    width: auto;
    display: flex;
    flex-direction: row;
    border: 2px dotted #ffffff;
    border-top: unset;
    font-size: 0.8rem;
    padding: 0px 10px 5px;
    height: 37px;
    margin: 0 5px;
    color: #ec2624;
}
.preregiBt:hover {
  background: #ee5c5c !important;
  color:#ffffff;
  border: 2px dotted #ffffff !important;
  border-top: unset !important;
}
.loginBt span,
.preregiBt span {
  text-wrap: nowrap;
}