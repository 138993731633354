.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.contentsBody{
  color: #374151;
}
a{
  font-weight: 700;
}
a:hover {
  color:rgb(8, 0, 100);
  border-bottom: 1px rgb(13, 0, 100);
  text-decoration-line: underline;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@font-face {
  font-family: MochiyP;
  src: url('./fonts/MochiyPopPOne-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Kosugi;
  src: url('./fonts/Kosugi-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: KosugiMaru;
  src: url('./fonts/KosugiMaru-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}